import { UserRegisterType } from "../../pages/auth/Register";
import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { username: string; password: string }) {
  const endpoint = "/accounts/public/login/";
  return api.create(`${endpoint}`, { ...params, role: "user" });
}

function getProfile() {
  const endpoint = "/accounts/user/profile/";
  return api.get(`${endpoint}`, {});
}

function logout() {
  const endpoint = "/logout/";
  return api.create(`${endpoint}`, {});
}

function signup(params: UserRegisterType) {
  const endpoint = "/accounts/public/register/";
  return api.create(`${endpoint}`, params);
}

function forgotPassword(params: { username: string }) {
  const endpoint = "/forget-password/";
  return api.create(`${endpoint}`, params);
}

export { login, logout, signup, forgotPassword, getProfile };
