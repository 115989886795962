import { APICore } from "./apiCore";

const api = new APICore();

export function savePaymentCards({
  setup_intent_id,
}: {
  setup_intent_id: string;
}) {
  const endpoint = `/payments/user/cards/`;
  return api.create(endpoint, { setup_intent_id });
}

export function getIntentInfo() {
  const endpoint = `/payments/user/secret/`;
  return api.get(endpoint, {});
}

export function getSavedCards() {
  const endpoint = `/payments/user/cards/`;
  return api.get(endpoint, {});
}

export function getCardDetails(id: string) {
  const endpoint = `/payments/user/cards/${id}`;
  return api.get(endpoint, {});
}
